import React from 'react';
import type { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import classNames from 'classnames';
import { Link, Tooltip } from 'design';
import Switch from 'rc-switch';

import dayjs from '@/date';
import InfoTooltip from '@/react/common/InfoTooltip';
import { UPPER_PANEL_HEIGHT } from './constants';
import ExportToPng from './ExportToPng';
import Search from './Search';

// round minutes to half an hour
const getLastUpdate = () => {
  return dayjs()
    .minute(dayjs().minute() > 30 ? 30 : 0)
    .format('HH:mm');
};

const separator = <div className="tw-inline-block tw-h-6 tw-w-px tw-bg-[#C5CBD6]"></div>;

const ActionsPanel = ({
  showActions = true,
  showTablesOnly = false,
  showTablesToggleAction = true,
  onShowTablesToggle = null,
  onClose = null,
  left = null,
  inModal = false,
  onOpenModal = null,
}: {
  showActions?: boolean;
  showTablesOnly?: boolean;
  showTablesToggleAction?: boolean;
  onShowTablesToggle?: (() => void) | null;
  onClose?: (() => void) | null;
  left?: ReactNode;
  inModal?: boolean;
  onOpenModal?: (() => void) | null;
}) => {
  return (
    <div
      className={classNames(
        'tw-z-10 tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-4 tw-self-start tw-p-6 tw-leading-5',
        { 'tw-absolute [&:has(+[data-sidebar])]:tw-w-[calc(100%-var(--sidebar-width))]': !inModal },
        { 'tw-bg-white tw-shadow-[0_-1px_0_0_#D9DEE6_inset]': inModal },
      )}
      style={{ height: UPPER_PANEL_HEIGHT }}
    >
      {left}
      <div
        className={classNames(
          'tw-flex tw-flex-1 tw-items-center tw-gap-4',
          left ? 'tw-justify-end' : 'tw-justify-between',
        )}
      >
        <div className="tw-inline-flex tw-items-center tw-gap-4">
          {showActions && (
            <>
              <span className="tw-flex tw-items-center tw-gap-2">
                <InfoTooltip
                  className="!tw-ml-0"
                  tooltip="Note: This information may be up to 30 minutes old. Python transformation analyses are highly accurate but may contain occasional errors."
                />
                <p className="tw-m-0 tw-text-sm">
                  <span className="tw-text-neutral-400">Last update:</span> {getLastUpdate()}
                </p>
              </span>
              {separator}
            </>
          )}
          {showActions && showTablesToggleAction && onShowTablesToggle && (
            <>
              <Tooltip
                className="xl:tw-hidden"
                tooltip="Show tables only"
                placement="left"
                type="action"
                triggerClassName="clickable tw-flex tw-items-center"
                triggerOnClick={onShowTablesToggle}
              >
                <Switch prefixCls="switch" className="btn-icon wider" checked={showTablesOnly} />
                <p className="tw-m-0 tw-hidden tw-text-sm xl:tw-flex">Show tables only</p>
                <FontAwesomeIcon
                  icon="table"
                  className="tw-flex tw-text-base tw-text-neutral-400 xl:tw-hidden"
                />
              </Tooltip>
              {separator}
            </>
          )}
          {showActions && (
            <>
              <Link href={URLS.USER_DOCUMENTATION}>
                <FontAwesomeIcon
                  icon="book-blank"
                  className="tw-mr-2 tw-text-sm tw-text-neutral-400"
                />
                Documentation
              </Link>
              {!!left && separator}
            </>
          )}
        </div>
        <div className="tw-inline-flex tw-items-center tw-gap-4">
          {showActions && (
            <>
              <Search inModal={inModal} />
              <span className="tw-flex tw-items-center">
                <ExportToPng />
              </span>
            </>
          )}
          {showActions && onOpenModal && (
            <span className="tw-flex tw-items-center">
              <Tooltip tooltip="Expand lineage to full screen" placement="left" type="action">
                <Button onClick={onOpenModal}>
                  <FontAwesomeIcon icon="up-right-and-down-left-from-center" />
                </Button>
              </Tooltip>
            </span>
          )}
          {onClose && (
            <span className="tw-flex tw-items-center">
              <Button onClick={onClose}>
                <FontAwesomeIcon icon="xmark" />
              </Button>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionsPanel;
