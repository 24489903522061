import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ControlButton, useReactFlow } from '@xyflow/react';
import { Tooltip } from 'design';

import type { CustomEdge, CustomNode } from '@/modules/lineage/rfTypes';
import { GraphContext } from './GraphContext';
import { getFitViewOptions } from './initConfig';

const CenterButton = ({ nodes }: { nodes: CustomNode[] }) => {
  const { mainNodeId, nodeDetail } = useContext(GraphContext);
  const { fitView } = useReactFlow<CustomNode, CustomEdge>();

  const nodeId = nodeDetail?.fqid ?? nodes.find((node) => node.id === mainNodeId)?.id;

  return (
    <Tooltip
      placement="left"
      tooltip={
        !nodeId
          ? 'No node available to center on'
          : nodeDetail
          ? 'Center on focused node'
          : 'Center on main node'
      }
      type="action"
    >
      <ControlButton
        disabled={!nodeId}
        onClick={() => nodeId && fitView(getFitViewOptions({ id: nodeId }))}
      >
        <FontAwesomeIcon icon="crosshairs" />
      </ControlButton>
    </Tooltip>
  );
};

export default CenterButton;
