import React from 'react';
import { Panel } from '@xyflow/react';
import { cn } from 'design';

import { componentTypes } from '@/constants/componentTypes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import type { CustomNode } from '@/modules/lineage/rfTypes';

type LegendType =
  | 'project'
  | 'table'
  | 'component'
  | (typeof componentTypes)[keyof typeof componentTypes]
  | 'other';

const Legend = (props: { nodes: CustomNode[] }) => {
  const nodeTypes = props.nodes.reduce<LegendType[]>((acc, node) => {
    const type = node.type === 'component' ? node.data.component?.type : node.type;

    if (type && !acc.includes(type)) {
      acc.push(type);
    }
    return acc;
  }, []);

  if (nodeTypes.length === 0) {
    return null;
  }

  return (
    <Panel position="bottom-left">
      <div className="tw-inline-flex tw-items-center tw-gap-8 tw-rounded-lg tw-bg-white tw-px-4 tw-py-3.5 tw-text-sm">
        {nodeTypes
          .sort((a, b) => getNewComponentTypeLabel(a).localeCompare(getNewComponentTypeLabel(b)))
          .map((type: string) => {
            return (
              <span key={type} className="tw-inline-flex tw-items-center tw-gap-2.5">
                <span
                  className={cn('tw-inline-flex tw-h-4 tw-w-4', {
                    'tw-bg-secondary-200': type === 'table',
                    'tw-bg-[#D6C2FF]': type === 'project',
                    'tw-bg-warning-300': type === componentTypes.TRANSFORMATION,
                    'tw-bg-primary-200': type === componentTypes.EXTRACTOR,
                    'tw-bg-cyan-200': type === componentTypes.WRITER,
                    'tw-bg-error-200': type === componentTypes.APPLICATION,
                  })}
                />
                {getNewComponentTypeLabel(type)}
              </span>
            );
          })}
      </div>
    </Panel>
  );
};

export default Legend;
