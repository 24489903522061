/**
 * kbc-ui: {
 *   'dev-credentials': {
 *     ...
 *   },
 *   'kbc-ui-dashboard-last-seen-PROJECT_ID': ...,
 *
 * }
 */

const ROOT = 'kbc-ui';
const DEV_CREDENTIALS = 'dev-credentials';
const MUTE_STORES_LOGGING = 'mute-stores-logging';
const IS_PROJECT_DESCRIPTION_HIDDEN = 'is-project-description-hidden';
const DASHBOARD_LAST_SEEN = 'kbc-ui-dashboard-last-seen';
const HIDE_WORKSPACES_BILLING_ALERT = 'hide-workspaces-billing-alert';
const HIDE_DASHBOARD_WELCOME_WIDGET = 'hide-dashboard-welcome-widget';
const SHOW_CHILD_JOBS = 'show-child-jobs';
const MAPPINGS_COLLAPSIONS = 'mappings-collapsions';
const PLATFORM_NEWS_LAST_SEEN = 'platform-news-last-seen';
const CODE_EDITOR_MODE = 'code-editor-mode';
const FLOW_TRANSFORM_STYLE = 'flow-transform-style';
const COLLAPSED_ALERTS = 'collapsed-alerts';
const COLLAPSED_CONFIGURATIONS = 'collapsed-configurations';
const COLLAPSED_PULL_CONFIGURATIONS = 'collapsed-pull-configurations';
const COLLAPSED_TRASH_CONFIGURATIONS = 'collapsed-trash-configurations';
const COLLAPSED_TEMPLATES = 'collapsed-templates';
const CONFIGURATIONS_SORT_BY = 'configurations-sort-by';
const CONFIGURATIONS_COMPONENTS_SORT_BY = 'configurations-components-sort-by';
const TABLE_COLUMNS_ORDER = 'table-columns-order';
const DEMO_PROJECT_USER_ID = 'demo-project-user-id';
const HIDE_CLI_BOX = 'hide-cli-box';
const LOCAL_STORAGE_FAV_PROJECT_KEY_PART = 'favouriteProjects';
const LINEAGE_NODES_DRAGGABLE = 'lineage-nodes-draggable';
const LINEAGE_LEGEND = 'lineage-legend';

export {
  ROOT,
  DEV_CREDENTIALS,
  MUTE_STORES_LOGGING,
  IS_PROJECT_DESCRIPTION_HIDDEN,
  DASHBOARD_LAST_SEEN,
  HIDE_WORKSPACES_BILLING_ALERT,
  MAPPINGS_COLLAPSIONS,
  HIDE_DASHBOARD_WELCOME_WIDGET,
  PLATFORM_NEWS_LAST_SEEN,
  CODE_EDITOR_MODE,
  FLOW_TRANSFORM_STYLE,
  SHOW_CHILD_JOBS,
  COLLAPSED_ALERTS,
  COLLAPSED_CONFIGURATIONS,
  COLLAPSED_PULL_CONFIGURATIONS,
  COLLAPSED_TRASH_CONFIGURATIONS,
  COLLAPSED_TEMPLATES,
  CONFIGURATIONS_SORT_BY,
  CONFIGURATIONS_COMPONENTS_SORT_BY,
  TABLE_COLUMNS_ORDER,
  DEMO_PROJECT_USER_ID,
  HIDE_CLI_BOX,
  LOCAL_STORAGE_FAV_PROJECT_KEY_PART,
  LINEAGE_NODES_DRAGGABLE,
  LINEAGE_LEGEND,
};
