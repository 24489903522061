import React from 'react';
import { Button } from 'react-bootstrap';
import type { Map } from 'immutable';

import lineage from '../../../images/lineage.svg';

import BetaTitle from '@/modules/lineage/BetaTitle';
import TablesLineage from '@/modules/lineage/TablesLineage/TablesLineage';
import RoutesStore from '@/stores/RoutesStore';

const ShowLineage = ({
  allComponents,
  startingPointFqid,
  isLocked,
  projectName,
  projectId,
  configurationName,
}: {
  allComponents: Map<string, any>;
  startingPointFqid: string;
  isLocked: boolean;
  projectName: string;
  projectId: string;
  configurationName: string;
}) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <BetaTitle>
        <Button
          bsStyle="link"
          className="btn-link-inline flex-container inline-flex flex-start no-wrap"
          onClick={(event: React.SyntheticEvent) => {
            event.stopPropagation();

            setShowModal(true);
          }}
        >
          <img src={lineage} alt="lineage" className="btn-icon text-muted tw-w-6" />
          Data Lineage
        </Button>
      </BetaTitle>
      {showModal && (
        <TablesLineage
          isLocked={isLocked}
          allComponents={allComponents}
          startingPointFqid={startingPointFqid}
          onCloseModal={() => {
            setShowModal(false);
            RoutesStore.getRouter().updateQuery({ node: null });
          }}
          projectName={projectName}
          projectId={projectId}
          nodeName={configurationName}
        />
      )}
    </>
  );
};

export default ShowLineage;
