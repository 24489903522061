import React from 'react';
import { resolve } from 'react-router-named-routes';
import Promise from 'bluebird';
import { List } from 'immutable';
import { rtrim } from 'underscore.string';

import {
  KEBOOLA_DATABRICKS_TRANSFORMATION,
  KEBOOLA_SHARED_CODE,
  KEBOOLA_VARIABLES,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_IS_SINGLE_TENANT, FEATURE_SANDBOXES_PYTHON_MLFLOW } from '@/constants/features';
import ComponentsActionCreators from '@/modules/components/ComponentsActionCreators';
import { features as componentFeatures } from '@/modules/components/Constants';
import { ensureComponentWithDetails } from '@/modules/components/helpers';
import InstalledComponentsActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import FileInputMappingHeaderButton from '@/modules/components/react/pages/file-input-mapping/HeaderButton';
import FileInputMappingPage from '@/modules/components/react/pages/file-input-mapping/Index';
import GenericIndex from '@/modules/components/react/pages/GenericIndex';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import createVersionsPageRoute from '@/modules/components/utils/createVersionsPageRoute';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import ConfigurationRowName from '@/modules/configurations/react/components/ConfigurationRowName';
import RowVersions from '@/modules/configurations/react/pages/Versions';
import RowVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import * as ModelsActions from '@/modules/model-services/Actions';
import ModelsHeaderButtons from '@/modules/model-services/HeaderButtons';
import ModelServicesIndex from '@/modules/model-services/Index';
import NoCodeHeaderButtons from '@/modules/no-code/components/Buttons';
import * as NotificationActions from '@/modules/notifications/actions';
import NotificationDetail from '@/modules/notifications/Detail';
import oracleTransformationCredentialsRoute from '@/modules/oracle-transformation/credentials-route';
import SharedCodesDetail from '@/modules/shared-codes/Detail';
import SharedCodesHeaderButton from '@/modules/shared-codes/HeaderButton';
import SharedCodesIndex from '@/modules/shared-codes/Index';
import StackFeaturesStore from '@/modules/stack-features/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import folderBreadcrumbHandler from '@/utils/folderBreadcrumbHandler';
import { configPoll, DEFAULT_INTERVAL } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import nextTick from '@/utils/nextTick';
import { routeNames } from './constants';
import DbtDiscovery from './DbtDiscovery';
import HeaderButtons from './HeaderButtons';
import Index from './Index';

const transformationDetailBreadcrumbHandler = ({ breadcrumbs, params }) => {
  return folderBreadcrumbHandler(breadcrumbs, params.component, params.config, routeNames.ROOT);
};

const routes = {
  name: routeNames.ROOT,
  title: 'Transformations',
  defaultRouteHandler: Index,
  headerButtonsHandler: HeaderButtons,
  onLeave: () => nextTick(InstalledComponentsActionsCreators.resetExpanedFolders),
  requireData: [
    (params, query, routerState) => {
      if (params.component) {
        return ComponentsActionCreators.loadComponent(params.component);
      }

      if (rtrim(routerState.location.pathname, '/') === resolve(routeNames.ROOT)) {
        return Promise.all([
          NotificationActions.loadNotifications(),
          StorageActionCreators.loadBucketsAndTables(),
          InstalledComponentsActionsCreators.reloadInstalledComponents({
            componentType: componentTypes.TRANSFORMATION,
            include: 'configuration',
          }),
          InstalledComponentsActionsCreators.loadComponentsMetadata(),
        ]);
      }

      return Promise.resolve();
    },
  ],
  childRoutes: [
    {
      name: routeNames.SHARED_CODES,
      title: 'Shared Code',
      defaultRouteHandler: SharedCodesIndex,
      headerButtonsHandler: SharedCodesHeaderButton,
      requireData: [
        () => InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_SHARED_CODE),
        () => InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_VARIABLES),
      ],
      childRoutes: [
        {
          name: routeNames.SHARED_CODE,
          path: ':config/:row',
          title(routerState) {
            return ConfigurationRowsStore.get(
              KEBOOLA_SHARED_CODE,
              routerState.getIn(['params', 'config']),
              routerState.getIn(['params', 'row']),
            ).get('name');
          },
          nameEdit(params) {
            return (
              <ConfigurationRowName
                key={`${KEBOOLA_SHARED_CODE}-${params.config}-${params.row}`}
                componentId={KEBOOLA_SHARED_CODE}
                configId={params.config}
                rowId={params.row}
                placeholder="Shared Code"
              />
            );
          },
          defaultRouteHandler: SharedCodesDetail,
          requireData: [
            ({ config, row }) =>
              RowVersionsActionCreators.loadVersions(KEBOOLA_SHARED_CODE, config, row),
          ],
          childRoutes: [
            {
              name: routeNames.SHARED_CODE_VERSIONS,
              path: 'versions',
              title: 'Versions',
              defaultRouteHandler: (props) => (
                <RowVersions {...props} componentId={KEBOOLA_SHARED_CODE} />
              ),
            },
          ],
        },
      ],
    },
    {
      name: routeNames.MODELS,
      title: 'ML/AI Services',
      defaultRouteHandler: ModelServicesIndex,
      headerButtonsHandler: ModelsHeaderButtons,
      requireData: [
        () => {
          if (
            !ApplicationStore.hasCurrentProjectFeature(FEATURE_SANDBOXES_PYTHON_MLFLOW) &&
            !StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT)
          ) {
            return Promise.resolve();
          }

          ModelsActions.loadModelsForce();
          return Promise.resolve();
        },
      ],
      poll: {
        interval: DEFAULT_INTERVAL,
        action: () => ModelsActions.loadPendingJobs(),
      },
    },
    {
      name: routeNames.GENERIC_TRANSFORMATION_CONFIG,
      title(routerState) {
        const configId = routerState.getIn(['params', 'config']);
        const component = routerState.getIn(['params', 'component']);
        return InstalledComponentsStore.getConfig(component, configId).get('name', configId);
      },
      breadcrumbHandler: transformationDetailBreadcrumbHandler,
      defaultRouteHandler: GenericIndex,
      headerButtonsHandler: NoCodeHeaderButtons,
      path: ':component/:config',
      requireData: [
        (params) => {
          return configRequiredData(params.component, params.config, (configData) => {
            if (configData.hasIn(['runtime', 'codePattern', 'componentId'])) {
              return ComponentsActionCreators.loadComponent(
                configData.getIn(['runtime', 'codePattern', 'componentId']),
              );
            }
          });
        },
        () => InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_SHARED_CODE),
        (params, query, routeState) => {
          const isFileInputMappingDetail = routeState.routes.find(
            (route) => route.path === 'file-input-mapping',
          );

          if (
            params.component !== KEBOOLA_DATABRICKS_TRANSFORMATION ||
            !!isFileInputMappingDetail
          ) {
            return Promise.resolve();
          }

          return InstalledComponentsActionsCreators.reloadInstalledComponents({ include: 'rows' });
        },
        (params) => {
          if (
            DevBranchesStore.isDevModeActive() ||
            !ApplicationStore.hasCurrentProjectFeature(FEATURE_SANDBOXES_PYTHON_MLFLOW)
          ) {
            return Promise.resolve();
          }

          return ensureComponentWithDetails(params.component).then((component) => {
            if (
              component.get('features', List()).includes(componentFeatures.MLFLOW_ARTIFACTS_ACCESS)
            ) {
              return ModelsActions.loadModels();
            }
          });
        },
      ],
      poll: configPoll(),
      childRoutes: [
        oracleTransformationCredentialsRoute,
        {
          name: routeNames.GENERIC_TRANSFORMATION_FILE_INPUT_MAPPING,
          path: 'file-input-mapping',
          title: 'File Input Mapping',
          breadcrumbHandler: transformationDetailBreadcrumbHandler,
          defaultRouteHandler: FileInputMappingPage,
          headerButtonsHandler: FileInputMappingHeaderButton,
          requireData: [
            () => {
              return InstalledComponentsActionsCreators.reloadInstalledComponents({
                include: 'configuration,rows',
              });
            },
          ],
        },
        {
          name: routeNames.GENERIC_TRANSFORMATION_NOTIFICATIONS,
          path: 'notifications',
          title: 'Notifications',
          breadcrumbHandler: transformationDetailBreadcrumbHandler,
          defaultRouteHandler: NotificationDetail,
          requireData: [() => NotificationActions.loadNotifications()],
        },
        createVersionsPageRoute(null, 'config', routeNames.GENERIC_TRANSFORMATION_VERSIONS, {
          breadcrumbHandler: transformationDetailBreadcrumbHandler,
        }),
        {
          name: routeNames.GENERIC_TRANSFORMATION_CONFIG_DISCOVERY,
          path: 'discovery',
          title: 'Discovery',
          breadcrumbHandler: transformationDetailBreadcrumbHandler,
          defaultRouteHandler: DbtDiscovery,
        },
      ],
    },
  ],
};

export default routes;
