import React, { type ReactNode } from 'react';
import ReactSelect, {
  type ActionMeta,
  type ClassNamesConfig,
  components,
  type ControlProps,
  type GroupBase,
  type GroupProps,
  type InputActionMeta,
  type MenuListProps,
  type MenuPlacement,
  type MenuPosition,
  type MultiValueGenericProps,
  type MultiValueProps,
  type OptionProps,
  type Options,
  type OptionsOrGroups,
  type SingleValueProps,
  type ValueContainerProps,
} from 'react-select';
import Creatable from 'react-select/creatable';
import Sortable from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Tooltip } from 'design';
import type { List } from 'immutable';
import { fromJS, Iterable } from 'immutable';
import _ from 'underscore';

import keyCodes from '@/constants/keyCodes';
import { defaultOptions } from '@/constants/sortable';
import getAllObjectValuesAsArray from '@/utils/getAllObjectValuesAsArray';
import matchByWords from '@/utils/matchByWords';
import Checkbox from './Checkbox';
import LazyList from './LazyList';
import MarkedText from './MarkedText';
import Truncated from './Truncated';

type Option = any;

type SelectProps = (
  | {
      multi: true;
      onChange: (value: List<any>, actionMeta: ActionMeta<Option | Option[] | null>) => void;
    }
  | {
      multi: false | undefined;
      onChange: (value: any, actionMeta: ActionMeta<Option | Option[] | null>) => void;
    }
) & {
  id?: string;
  name?: string;
  value?: any;
  inputValue?: string;
  options?: Option[];
  searchable?: boolean;
  clearable?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string | ReactNode;
  noResultsText?: string;
  isLoading?: boolean;
  className?: string;
  menuIsOpen?: boolean;
  allowCreate?: boolean;
  emptyStrings?: boolean;
  hideSelectAllOptions?: boolean;
  trimMultiCreatedValues?: boolean;
  defaultOpenWithFocus?: boolean;
  forceOpen?: boolean;
  noDropdown?: boolean;
  asSearch?: boolean;
  menuPlacement?: MenuPlacement;
  classNames?: ClassNamesConfig;
  onBlur?: (event: React.SyntheticEvent) => void;
  onInputChange?: (inputValue: string) => string | void;
  promptTextCreator?: (inputValue: string) => string;
  isValidNewOption?: (inputValue: string) => boolean;
  newOptionCreator?: (inputValue: string) => Option;
  inputValueSanitizer?: (inputValue: string) => string;
  filterOption?: (option: Option, inputValue: string) => boolean;
  noOptionRenderer?: (props: any) => React.ReactElement;
  singleValueRenderer?: (props: any) => React.ReactElement;
  multiValueRenderer?: (props: any) => React.ReactElement;
  controlRenderer?: (props: any) => React.ReactElement;
  multiValueLabel?: (props: any) => React.ReactElement;
};

const MAX_OPTIONS = 5000;
const ALL_OPTIONS_VALUE = '[[all]]';
const EMPTY_STRING = { label: <code>[empty string]</code>, value: '%_EMPTY_STRING_%' };
const SPACE_CHARACTER = { label: <code>[space character]</code>, value: '%_SPACE_CHARACTER_%' };

class Select extends React.Component<SelectProps> {
  static defaultProps = {
    value: '',
    clearable: true,
    searchable: true,
    multi: false,
    disabled: false,
    isLoading: false,
    allowCreate: false,
    emptyStrings: false,
    hideSelectAllOptions: false,
    trimMultiCreatedValues: false,
  };

  state = {
    inputValue: '',
    forceFixedMenu: false,
  };

  render() {
    if (this.props.allowCreate) {
      return <Creatable {...this.prepareProps()} />;
    }

    return <ReactSelect {...this.prepareProps()} />;
  }

  onInputChange = (inputValue: string, actionMeta: InputActionMeta) => {
    if (
      this.props.multi &&
      this.props.options &&
      this.props.options.length !== 0 &&
      !!actionMeta.prevInputValue &&
      actionMeta.action === 'set-value'
    ) {
      return;
    }

    if (
      !this.props.disabled &&
      this.props.allowCreate &&
      actionMeta.action === 'input-blur' &&
      actionMeta.prevInputValue
    ) {
      const inputValue = actionMeta.prevInputValue;
      const value = this.props.inputValueSanitizer?.(inputValue) ?? inputValue;

      const option: Option = { value, label: value, __isNew__: true };
      const currentValue = this.mapValues();

      if (
        Array.isArray(currentValue)
          ? currentValue.some(({ value }) => value === option.value)
          : currentValue?.value === option.value
      ) {
        return;
      }

      const selected = Array.isArray(currentValue) ? [...currentValue, option] : option;

      this.onChange(selected, { action: 'create-option', option });
    }

    this.setState({ inputValue: this.props.inputValueSanitizer?.(inputValue) ?? inputValue });
  };

  onChange = (
    selected: Option | Option[] | null,
    actionMeta: ActionMeta<Option | Option[] | null>,
  ) => {
    if (!Array.isArray(selected)) {
      return this.props.onChange(selected?.value ?? '', actionMeta);
    }

    return this.props.onChange(
      fromJS(
        this.prepareSelectedOptions(selected).map((option: Option) => {
          if (option.value === EMPTY_STRING.value) {
            return '';
          }

          if (option.value === SPACE_CHARACTER.value) {
            return ' ';
          }

          if (this.props.trimMultiCreatedValues) {
            return option.value.trim();
          }

          return option.value;
        }),
      ),
      actionMeta,
    );
  };

  prepareOptions() {
    let options = [...this.getOptions()];

    if (options.length > 0 && this.props.multi) {
      const values = this.mapValues() as Option[];

      if (values.length > 0) {
        const flatOptions = this.getOptions({ flatten: true });

        options = [
          ...options,
          ...values.filter((value) => !flatOptions.some((option) => _.isEqual(option, value))),
        ];
      }
    }

    if (this.props.emptyStrings) {
      if (!options.find((option) => option.value === EMPTY_STRING.value)) {
        options = options.concat(EMPTY_STRING);
      }

      if (!options.find((option) => option.value === SPACE_CHARACTER.value)) {
        options = options.concat(SPACE_CHARACTER);
      }
    }

    if (options.length > 0 && this.props.multi && !this.props.hideSelectAllOptions) {
      options = [
        {
          value: ALL_OPTIONS_VALUE,
          label: 'Select All',
          className: 'f-12 uppercase font-semibold',
        },
        ...options,
      ];
    }

    return options;
  }

  getOptions(options?: { flatten?: boolean }): Option[] {
    if (!this.props.options) {
      return [];
    }

    if (options?.flatten) {
      return this.ensureFlattenedOptions(this.props.options);
    }

    return this.props.options;
  }

  prepareSelectedOptions = (selected: Option[]) => {
    const isAllOptionsSelected = selected.some((option) => option.value === ALL_OPTIONS_VALUE);
    const values = this.mapValues();

    if (isAllOptionsSelected && values) {
      return values.length > 0 ? [] : this.prepareOptions().slice(1);
    }

    return selected
      .map((option) => {
        if (option.__isNew__ && option.value?.includes(',')) {
          return option.value
            .split(',')
            .map((item: string) => item.trim())
            .map((item: string) => ({ value: item, label: item, __isNew__: true }));
        }

        return option;
      })
      .flat();
  };

  mapValues() {
    const value = Iterable.isIterable(this.props.value)
      ? this.props.value.toJS()
      : this.props.value;

    return this.props.multi ? this.mapValuesMulti(value) : this.mapValuesSingle(value);
  }

  mapValuesSingle(value: any) {
    const selectedOption = this.getOptions({ flatten: true }).find(
      (option) => option.value === value,
    );

    if (typeof value === 'undefined' || (value === '' && !selectedOption)) {
      return null;
    }

    return selectedOption || { label: value, value };
  }

  mapValuesMulti(values: []) {
    if (!values?.length) {
      return [];
    }

    const allOptions = this.getOptions({ flatten: true });

    return values.map((value) => {
      if (value === '') {
        return EMPTY_STRING;
      }

      if (value === ' ') {
        return SPACE_CHARACTER;
      }

      return allOptions.find((option) => option.value === value) || { label: value, value };
    });
  }

  filterOption = (input: Option & { data?: Option }, inputValue: string) => {
    if (!inputValue) {
      return true;
    }

    if (input.value === ALL_OPTIONS_VALUE) {
      return false;
    }

    if (this.props.filterOption && typeof input.data === 'object') {
      return this.props.filterOption(input.data, inputValue);
    }

    let optionValueToSearchFor =
      input.data?.name ?? input.name ?? (_.isString(input.label) ? input.label : input.value);

    if (_.isObject(optionValueToSearchFor)) {
      optionValueToSearchFor = getAllObjectValuesAsArray(optionValueToSearchFor);
    }

    return input.value === inputValue || matchByWords(optionValueToSearchFor, inputValue);
  };

  isValidNewOption = (
    inputValue: string,
    value: Options<Option>,
    options: OptionsOrGroups<Option, GroupBase<Option>>,
  ) => {
    return !(
      !inputValue ||
      value.some((option) => option.value === inputValue) ||
      options.some((option) => option.value === inputValue)
    );
  };

  handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === keyCodes.ESCAPE) {
      e.stopPropagation();
    }
  };

  ensureFlattenedOptions = (options: Option[]) => {
    return options.some((option) => option.options)
      ? options.reduce((all, option) => all.concat(option.options || option), [])
      : options;
  };

  prepareProps() {
    const value = this.mapValues();
    const options = this.prepareOptions();
    const hideDropdown =
      this.props.noDropdown || this.props.forceOpen || (this.props.allowCreate && !options.length);
    const inputValue = this.getInputValue();

    const commonProps = {
      value,
      options,
      inputValue,
      inputId: this.props.id,
      isMulti: this.props.multi,
      classNames: this.props.classNames,
      isSearchable: this.props.searchable,
      isClearable: this.props.clearable,
      backspaceRemovesValue: this.props.clearable,
      isDisabled: this.props.disabled,
      autoFocus: this.props.autoFocus || this.props.defaultOpenWithFocus,
      defaultMenuIsOpen: this.props.defaultOpenWithFocus,
      name: this.props.name,
      placeholder: this.props.placeholder || this.getDefaultPlaceholder(),
      onChange: this.onChange,
      onBlur: this.props.onBlur,
      isLoading: this.props.isLoading,
      onInputChange: this.props.onInputChange || this.onInputChange,
      formatCreateLabel: this.props.promptTextCreator,
      filterOption: this.filterOption,
      tabSelectsValue: false,
      hideSelectedOptions: false,
      closeMenuOnSelect: !this.props.multi,
      menuIsOpen: this.props.menuIsOpen,
      menuPlacement: this.props.menuPlacement,
      onMenuOpen: this.checkSelectPosition,
      ...(this.props.allowCreate && {
        isValidNewOption: this.props.isValidNewOption || this.isValidNewOption,
      }),
      ...(this.props.newOptionCreator && {
        getNewOptionData: (inputValue: string) => {
          return { ...this.props.newOptionCreator?.(inputValue), __isNew__: true };
        },
      }),
      ...(this.state.forceFixedMenu && {
        menuPosition: 'fixed' as MenuPosition,
        menuPortalTarget: document.body,
        styles: { menuPortal: (base: any) => ({ ...base, zIndex: 1050 }) },
      }),
      classNamePrefix: 'react-select',
      onKeyDown: this.handleOnKeyDown,
      className: classnames('Select', this.props.className, {
        static: !this.props.onChange,
        'with-search-icon': this.props.asSearch,
      }),
      noOptionsMessage: () => {
        if (this.props.allowCreate && !options.length) {
          return this.props.multi && value.some((option: Option) => option.value === inputValue)
            ? 'Already added'
            : null;
        }

        return this.props.noResultsText;
      },
      components: {
        MenuList: LazyMenuList,
        Group: LazyGroup,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        ...(this.props.asSearch && { Control: SearchControl }),
        ...(this.props.multi && {
          MultiValue: SortableMultiValue,
          ValueContainer: SortableValueContainer,
          MultiValueLabel: CustomMultiValueLabel,
        }),
        ...(this.props.multiValueRenderer && {
          MultiValueContainer: this.props.multiValueRenderer,
        }),
        ...(this.props.singleValueRenderer && { SingleValue: this.props.singleValueRenderer }),
        ...(this.props.noOptionRenderer && { NoOptionsMessage: this.props.noOptionRenderer }),
        ...(this.props.controlRenderer && { Control: this.props.controlRenderer }),
        ...(hideDropdown && { DropdownIndicator: null }),
      },
      ...(hideDropdown && { openMenuOnClick: false, openMenuOnFocus: false }),
      ...(this.props.forceOpen && { menuIsOpen: true }),
    };

    const allOptions = this.ensureFlattenedOptions(commonProps.options);

    if (commonProps.isSearchable && allOptions.length > MAX_OPTIONS) {
      const filteredOptions = commonProps.inputValue
        ? allOptions.filter((option: Option) => this.filterOption(option, commonProps.inputValue))
        : allOptions;

      if (filteredOptions.length > MAX_OPTIONS) {
        commonProps.options = [];
        commonProps.noOptionsMessage = () =>
          `Too many options (${filteredOptions.length}). Please refine your search.`;
      } else {
        commonProps.options = filteredOptions;
        commonProps.filterOption = null!;
      }
    }

    return commonProps;
  }

  getInputValue = () => {
    return this.props.inputValue || this.state.inputValue;
  };

  getDefaultPlaceholder = () => {
    return this.props.allowCreate ? 'Select or create...' : 'Select...';
  };

  checkSelectPosition = () => {
    const isModalOpen = document.body.classList.contains('modal-open');

    this.setState({ forceFixedMenu: isModalOpen });
  };
}

const SearchControl = (props: ControlProps<Option>) => {
  return (
    <components.Control {...props}>
      <FontAwesomeIcon icon="magnifying-glass" className="tw-text-base tw-text-neutral-400" />
      {props.children}
    </components.Control>
  );
};

const SortableValueContainer = (props: ValueContainerProps) => {
  return (
    <div className="select-sortable">
      <Sortable
        style={props.getStyles('valueContainer', props)}
        className={props.cx(
          {
            'value-container': true,
            'value-container--is-multi': props.isMulti,
            'value-container--has-value': props.hasValue,
          },
          props.className,
        )}
        options={{
          ...defaultOptions,
          delay: 300,
          disabled: props.isDisabled,
          filter: '.react-select__multi-value__remove',
        }}
        onChange={(order: any, sortable: any, event: { newIndex: number; oldIndex: number }) => {
          const value = props.getValue();
          const orderedValue = fromJS(value)
            .splice(event.oldIndex, 1)
            .splice(event.newIndex, 0, value[event.oldIndex])
            .toJS();

          props.selectProps.onChange(orderedValue, { action: 'reorder-options' } as any);
        }}
      >
        {props.children}
      </Sortable>
    </div>
  );
};

const SortableMultiValue = (props: MultiValueProps<Option>) => {
  return (
    <span data-id={props.data.value} className="drag-handle">
      <components.MultiValue {...props} innerProps={{ onMouseDown: (e) => e.preventDefault() }} />
    </span>
  );
};

const LazyMenuList = (props: MenuListProps<Option>) => {
  return (
    <components.MenuList {...props} maxHeight={320}>
      <LazyList useWindow={false} items={React.Children.toArray(props.children)} />
    </components.MenuList>
  );
};

const LazyGroup = (props: GroupProps<Option>) => {
  const menuListElement: HTMLElement | null | undefined = document
    .getElementById(props.headingProps.id)
    ?.closest('.react-select__menu-list');

  if (!menuListElement) {
    return <components.Group {...props} />;
  }

  return (
    <components.Group {...props}>
      <LazyList
        useWindow={false}
        items={React.Children.toArray(props.children)}
        getScrollParent={() => menuListElement}
      />
    </components.Group>
  );
};

const CustomOption = (props: OptionProps<Option>) => {
  const renderOption = (body: ReactNode) => {
    if (props.data.isDisabled && (props.data.disabledReason || props.data.disabledReasonWithLink)) {
      return (
        <Tooltip
          placement="top"
          type="explanatory"
          allowHoverTooltip={!!props.data.disabledReasonWithLink}
          tooltip={props.data.disabledReason || props.data.disabledReasonWithLink}
        >
          {body}
        </Tooltip>
      );
    }

    return body;
  };

  if (props.data.divider) {
    return <hr />;
  }

  if (props.selectProps.isMulti) {
    const isAllOptionsValue = props.data.value === ALL_OPTIONS_VALUE;
    let availableOptionsCount = 0;
    let selectedOptionsCount = 0;

    if (isAllOptionsValue) {
      availableOptionsCount = props.options.length - 1;
      selectedOptionsCount = props.selectProps.value.length;
    }

    if (props.data.isGroupOption) {
      availableOptionsCount = props.options
        .filter(
          (option: Option) =>
            props.selectProps.filterOption?.(option, props.selectProps.inputValue),
        )
        .filter(
          ({ parentOption }: { parentOption: Option }) => parentOption === props.data.value,
        ).length;
      selectedOptionsCount = props.selectProps.value
        .filter(
          (option: Option) =>
            props.selectProps.filterOption?.(option, props.selectProps.inputValue),
        )
        .filter(
          ({ parentOption }: { parentOption: Option }) => parentOption === props.data.value,
        ).length;
    }

    const isSomeSelected = selectedOptionsCount > 0;
    const isAllSelected = isSomeSelected && selectedOptionsCount === availableOptionsCount;

    return renderOption(
      <components.Option
        {...props}
        innerProps={_.omit(props.innerProps, 'onMouseMove', 'onMouseOver')}
        className={classnames('flex-container flex-start', props.data.className)}
      >
        <Checkbox
          className={classnames({ hidden: props.data.__isNew__ })}
          disabled={props.isDisabled && !isSomeSelected}
          checked={isAllOptionsValue || props.data.isGroupOption ? isAllSelected : props.isSelected}
          onChange={() => props.selectOption(props.data)}
          {...((isAllOptionsValue || props.data.isGroupOption) && {
            indeterminate: isSomeSelected && !isAllSelected,
          })}
        />
        <div className="fill-space">
          <OptionLabel option={props.data} inputValue={props.selectProps.inputValue} />
        </div>
      </components.Option>,
    );
  }

  return renderOption(
    <components.Option
      {...props}
      innerProps={_.omit(props.innerProps, 'onMouseMove', 'onMouseOver')}
      className={props.data.className}
    >
      <OptionLabel option={props.data} inputValue={props.selectProps.inputValue} />
    </components.Option>,
  );
};

const CustomSingleValue = (props: SingleValueProps<Option>) => {
  return (
    <components.SingleValue {...props}>
      <SimpleLabel option={props.data} isValue />
    </components.SingleValue>
  );
};

const CustomMultiValueLabel = (props: MultiValueGenericProps<Option>) => {
  return (
    <components.MultiValueLabel {...props}>
      <SimpleLabel option={props.data} isValue />
    </components.MultiValueLabel>
  );
};

const OptionLabel = (props: { option: Option; inputValue: string }) => {
  if (
    !props.option.__isNew__ &&
    _.isString(props.option.label) &&
    props.option.value !== ALL_OPTIONS_VALUE
  ) {
    if (props.option.isDefault) {
      return (
        <div className="flex-container">
          <MarkedText source={props.option.label} mark={props.inputValue} />

          <span className="ml-auto text-muted mrp-1 f-12 line-height-16">Default</span>
        </div>
      );
    }

    return <MarkedText source={props.option.label} mark={props.inputValue} />;
  }

  return <SimpleLabel option={props.option} inputValue={props.inputValue} />;
};

const SimpleLabel = (props: { option: Option; inputValue?: string; isValue?: boolean }) => {
  const label = props.isValue
    ? props.option.selectedLabel ?? props.option.label
    : props.option.label;

  return _.isFunction(label) ? (
    label(props.inputValue || '')
  ) : props.isValue && _.isString(label) ? (
    <Truncated text={label} />
  ) : (
    label
  );
};

export default Select;
