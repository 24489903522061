export const actionTypes = {
  LOAD_SOURCES_SUCCESS: 'LOAD_SOURCES_SUCCESS',
  LOAD_SOURCE_SUCCESS: 'LOAD_SOURCE_SUCCESS',
  DELETE_SOURCE_SUCCESS: 'DELETE_SOURCE_SUCCESS',
  LOAD_SINKS_SUCCESS: 'LOAD_SINKS_SUCCESS',
  LOAD_SINK_SETTINGS_SUCCESS: 'LOAD_SINK_SETTINGS_SUCCESS',
  LOAD_SINK_STATISTICS_SUCCESS: 'LOAD_SINK_STATISTICS_SUCCESS',
  LOAD_SINK_STATISTICS_TOTAL_SUCCESS: 'LOAD_SINK_STATISTICS_TOTAL_SUCCESS',
  CLEAR_SINK_STATISTICS: 'CLEAR_SINK_STATISTICS',
} as const;

export const EXAMPLES_LANGUAGES = {
  PYTHON: 'Python',
  JAVASCRIPT: 'JavaScript',
  BASH: 'Bash',
} as const;

export type ExampleLanguageName = (typeof EXAMPLES_LANGUAGES)[keyof typeof EXAMPLES_LANGUAGES];

export type Conditions = {
  'storage.level.target.import.trigger.interval': string;
  'storage.level.target.import.trigger.size': string;
  'storage.level.target.import.trigger.count': number;
};

export const INITIAL_SINK = {
  name: '',
  type: 'table',
  table: {
    tableId: '',
    type: 'keboola',
    mapping: {
      columns: [
        { name: 'id', type: 'uuid' },
        { name: 'datetime', type: 'datetime' },
        { name: 'ip', type: 'ip' },
        { name: 'body', type: 'body' },
        { name: 'headers', type: 'headers' },
      ],
    },
  },
} as const;
