import React from 'react';
import type { NodeProps } from '@xyflow/react';

import type { OnSelectProject, ProjectNode } from '@/modules/lineage/rfTypes';
import BaseNode from './BaseNode';

const Project = ({ data, onClick }: NodeProps<ProjectNode> & { onClick: OnSelectProject }) => {
  const handleClick = () => {
    if (!onClick) {
      return;
    }

    onClick(data.name, data.id);
  };

  return (
    <BaseNode
      isLink
      nodeId={data.fqid}
      nodeTitle={data.name}
      nodeType="PRJ"
      iconName={['far', 'square-kanban']}
      onClick={handleClick}
      iconClassName="tw-text-neutral-400 tw-fill-neutral-400"
      backgroundColor="tw-bg-[#D6C2FF]"
    />
  );
};

export default Project;
